<div class="at-d-flex">
  <div>
    <ecommerce-side-navbar
      [parentNavbarItems]="tradeSideNavbarItems"
      [isFixed]="true" />
  </div>

  <div class="at-w-100">
    <ecommerce-top-navbar [showLogo]="false" [showEndSection]="true" />

    <div class="pt-6rem">
      <router-outlet />
    </div>
  </div>
</div>
