import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { FooterComponent } from './common/components/footer/footer.component';
import { TopNavbarComponent } from './common/components/top-navbar/top-navbar.component';
import { Title } from '@angular/platform-browser';
import { ToastComponent } from './common/components/toast/toast.component';
import { ModalComponent } from './common/components/modal/modal.component';
import { environment } from '../environments/environment';
import * as amplitude from '@amplitude/analytics-browser';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    TranslocoModule,
    TopNavbarComponent,
    FooterComponent,
    ToastComponent,
    ModalComponent,
  ],
  selector: 'ecommerce-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(
    private router: Router,
    private title: Title,
    private translocoService: TranslocoService
  ) {
    this.updatePageTitle();
    // add amplitude script that contains the key
    amplitude.init(environment.amplitude.key, {
      serverZone: environment.amplitude.serverZone as 'EU' | 'US',
      autocapture: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
    });
  }

  updatePageTitle() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setUrl(event);
      }
    });
  }

  setUrl(event: NavigationEnd) {
    const url: string = event.url;
    this.title.setTitle(this.getPageTitle(url));
  }

  getPageTitle(url: string): string {
    switch (true) {
      case url === '/': {
        return 'Boxooo';
      }
      case url === '/privacy-policy': {
        return 'Privacy Policy';
      }
      case url === '/terms-and-conditions': {
        return 'Terms and Conditions';
      }
      case url === '/contact-us': {
        return this.translocoService.translate('contact_us_screen_title');
      }
      case url === '/login': {
        return this.translocoService.translate('sign_in_screen_title');
      }
      case url === '/register': {
        return this.translocoService.translate('join_us_partner_screen_title');
      }
      case url === '/register/buyer': {
        return this.translocoService.translate('join_us_screen_buyer_title');
      }
      case url === '/register/supplier': {
        return this.translocoService.translate('join_us_screen_supplier_title');
      }
      case url === '/become-supplier': {
        return this.translocoService.translate(
          'supplier_registeration_screen_title'
        );
      }
      case url === '/trade/product-catalogue/all': {
        return this.translocoService.translate(
          'product_catalogue_screen_title'
        );
      }
      case url === '/trade/product-catalogue/approved': {
        return this.translocoService.translate(
          'approved_products_screen_approved_products_title'
        );
      }
      case url === '/trade/product-catalogue/unapproved': {
        return this.translocoService.translate(
          'unapproved_products_screen_unapproved_products_title'
        );
      }
      case url === '/trade/add-product': {
        return this.translocoService.translate('add_new_product_screen_title');
      }
      case url.includes('/trade/product/'): {
        return this.translocoService.translate(
          'edit_product_screen_edit_product_title'
        );
      }
      case url === '/trade/my-orders': {
        return this.translocoService.translate(
          'order_supplier_list_screen_title'
        );
      }
      case url.includes('/trade/order-details/'): {
        return this.translocoService.translate(
          'order_supplier_details_screen_order_summary_title'
        );
      }
      case url.includes('/product/'): {
        return this.translocoService.translate(
          'buyer_product_details_screen_title'
        );
      }
      case url === '/order-steps/shopping-bag': {
        return this.translocoService.translate(
          'shopping_bag_screen_order_steps_shopping_bag_title'
        );
      }
      case url === '/order-steps/shipping': {
        return this.translocoService.translate(
          'shipping_details_screen_shipping_details_title'
        );
      }
      case url === '/order-steps/confirmation': {
        return this.translocoService.translate(
          'shopping_bag_screen_order_steps_confirmation_title'
        );
      }
      case url.includes('/order-confirmation/'): {
        return this.translocoService.translate(
          'order_confirmation_screen_order_confirmed_title'
        );
      }
      case url === '/orders-list': {
        return this.translocoService.translate(
          'order_list_buyer_screen_my_orders_title'
        );
      }
      case url.includes('/order-details/'): {
        return this.translocoService.translate(
          'order_buyer_details_screen_order_summary_title'
        );
      }
      case url === '/product-store': {
        return 'Product store';
      }
      case url === '/settings' || url === '/trade/settings/profile': {
        return this.translocoService.translate(
          'settings_screen_profile_tab_label'
        );
      }
      case url === '/trade/settings/account-management': {
        return this.translocoService.translate(
          'settings_screen_account_management_tab_label'
        );
      }
      case url === '/reset-password': {
        return this.translocoService.translate('reset_password_screen_title');
      }
      case url === '/verification-code-otp': {
        return this.translocoService.translate(
          'forget_password_otp_screen_title'
        );
      }
      case url === '/create-new-password': {
        return this.translocoService.translate(
          'create_new_password_screen_title'
        );
      }
      default: {
        return this.translocoService.translate(
          'landing_screen_marketplace_label'
        );
      }
    }
  }
}
