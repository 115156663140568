<div class="modal-container" [ngClass]="{ active: isOpen }">
  <div class="modal at-shadow-mega-large" [ngClass]="modalClass()">
    @if (hasCloseIcon) {
      <div class="at-text-end at-mb-2">
        <button type="button" (click)="closeModal()">
          <img src="assets/images/svg/close.svg" alt="close icon" />
        </button>
      </div>
    }

    @if (title) {
      <h2 class="at-header2 at-fw-500 at-mb-4">{{ title }}</h2>
    }

    @if (description) {
      <p class="at-fw-400 at-line-height-150">
        {{ description }}
      </p>
    }

    @if (customizeContent) {
      <ng-container [ngTemplateOutlet]="customizeContent" />
    }
  </div>
</div>

<div
  class="overlay"
  [ngClass]="{ active: isOpen }"
  (click)="!canNotCloseFromOutside && closeModal()"></div>
