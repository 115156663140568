/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../components/toast/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  router = inject(Router);
  toastService = inject(ToastService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('🚀 ~ ErrorInterceptor ~ catchError ~ error:', error);
        if (
          error.status === 401 ||
          (error.error.message &&
            (error.error.message.includes('Unauthenticated') ||
              error.error.message.includes('unauthorized')))
        ) {
          this.router.navigate(['/login']);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('supplier');

          this.toastService.show({
            toastState: 'error',
            message: error.error.message as string,
          });
        }

        let errorObj: { code?: unknown; message: unknown; errors?: unknown };
        if (error.error.code) {
          errorObj = {
            code: error.error.code,
            message: error.error.message,
            errors: error.error.errors,
          };
        } else {
          errorObj = {
            message: error.error ? error.error.message : error.message,
          };
        }
        console.error('🚀 ~ errorObj:', errorObj);
        return throwError(() => errorObj);
      })
    );
  }
}
