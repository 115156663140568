import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SideNavbarComponent } from '../../common/components/side-navbar/side-navbar.component';
import { TopNavbarComponent } from '../../common/components/top-navbar/top-navbar.component';
import { ParentNavbarItem } from '../../common/components/side-navbar/side-navbar.interface';

@Component({
  selector: 'ecommerce-trade',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    SideNavbarComponent,
    TopNavbarComponent,
  ],
  templateUrl: './trade.component.html',
  styleUrl: './trade.component.scss',
})
export class TradeComponent {
  tradeSideNavbarItems: ParentNavbarItem[] = [
    {
      id: 1,
      parentLabel: this.translocoService.translate(
        'side_supplier_menu_product_management_group_label'
      ),
      showChildren: true,
      childrenNavbarList: [
        {
          childLabel: this.translocoService.translate(
            'side_supplier_menu_product_catalogue_item_label'
          ),
          childIcon: 'shopping',
          childRoute: '/trade/product-catalogue/all',
        },
        {
          childLabel: this.translocoService.translate(
            'side_supplier_menu_approved_products_item_label'
          ),
          childIcon: 'shopping',
          childRoute: '/trade/product-catalogue/approved',
        },
        {
          childLabel: this.translocoService.translate(
            'side_supplier_menu_unapproved_products_item_label'
          ),
          childIcon: 'shopping',
          childRoute: '/trade/product-catalogue/unapproved',
        },
      ],
    },
    {
      id: 2,
      parentLabel: this.translocoService.translate(
        'side_supplier_menu_orders_group_label'
      ),
      showChildren: true,
      childrenNavbarList: [
        {
          childLabel: this.translocoService.translate(
            'side_supplier_menu_my_orders_item_label'
          ),
          childIcon: 'shopping',
          childRoute: '/trade/my-orders',
        },
      ],
    },
  ];

  constructor(private translocoService: TranslocoService) {}
}
