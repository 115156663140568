import { Route } from '@angular/router';
import { TradeComponent } from './pages/trade/trade.component';
import { tradeAuthGuard } from './common/guards/trade-auth.guard';
import { AuthGuard } from './common/guards/auth.guard';
import { preventBackGuard } from './common/guards/prevent-back.guard';
import { NotLoggedInAuthGuard } from './common/guards/not-logged-in-auth.guard';

export const appRoutes: Route[] = [
  {
    path: 'presentation',
    loadComponent: () =>
      import('./pages/presentation/presentation.component').then(
        m => m.PresentationComponent
      ),
  },
  {
    path: 'trade',
    component: TradeComponent,
    canActivate: [tradeAuthGuard],
    children: [
      {
        path: 'add-product',
        loadComponent: () =>
          import(
            './pages/trade/product-management/add-product/add-product.component'
          ).then(m => m.AddProductComponent),
      },
      {
        path: 'settings/profile',
        loadComponent: () =>
          import('./pages/auth/settings/settings.component').then(
            m => m.SettingsComponent
          ),
      },
      {
        path: 'settings/account-management',
        loadComponent: () =>
          import('./pages/auth/settings/settings.component').then(
            m => m.SettingsComponent
          ),
      },
      {
        path: 'settings/change-password',
        loadComponent: () =>
          import('./pages/auth/settings/settings.component').then(
            m => m.SettingsComponent
          ),
      },
      {
        path: 'product/:id',
        loadComponent: () =>
          import(
            './pages/trade/product-management/add-product/add-product.component'
          ).then(m => m.AddProductComponent),
      },
      {
        path: 'product-catalogue/:state',
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/trade/product-management/product-catalogue/product-catalogue.component'
          ).then(m => m.ProductCatalogueComponent),
      },
      {
        path: 'my-orders',
        loadComponent: () =>
          import(
            './pages/trade/order-management/order-supplier-list/order-supplier-list.component'
          ).then(m => m.OrderSupplierListComponent),
      },
      {
        path: 'order-details/:reference',
        loadComponent: () =>
          import(
            './pages/trade/order-management/order-supplier-detalis/order-supplier-details.component'
          ).then(m => m.OrderSupplierDetailsComponent),
      },
      { path: '**', redirectTo: 'product-catalogue/all' },
    ],
  },
  {
    path: 'login',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'reset-password',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import(
        './pages/auth/reset-password-flow/reset-password/reset-password.component'
      ).then(m => m.ResetPasswordComponent),
  },
  {
    path: 'verification-code-otp',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import(
        './pages/auth/reset-password-flow/verification-code-otp/verification-code-otp.component'
      ).then(m => m.VerificationCodeOtpComponent),
  },
  {
    path: 'create-new-password',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import(
        './pages/auth/reset-password-flow/create-new-password/create-new-password.component'
      ).then(m => m.CreateNewPasswordComponent),
  },
  {
    path: 'register',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import('./pages/auth/register/register.component').then(
        m => m.RegisterComponent
      ),
  },
  {
    path: 'register/:partner',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import('./pages/auth/buyer-register/buyer-register.component').then(
        m => m.BuyerRegisterComponent
      ),
  },
  {
    path: 'become-supplier',
    loadComponent: () =>
      import('./pages/auth/become-supplier/become-supplier.component').then(
        m => m.BecomeSupplierComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import(
        './pages/marketplace/privacy-policy/privacy-policy.component'
      ).then(m => m.PrivacyPolicyComponent),
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () =>
      import(
        './pages/marketplace/terms-and-conditions/terms-and-conditions.component'
      ).then(m => m.TermsAndConditionsComponent),
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./pages/marketplace/contact-us/contact-us.component').then(
        m => m.ContactUsComponent
      ),
  },
  {
    path: 'product/:id',
    loadComponent: () =>
      import(
        './pages/marketplace/product-buyer-details/product-buyer-details.component'
      ).then(m => m.ProductBuyerDetailsComponent),
  },
  {
    path: 'order-confirmation/:reference',
    canActivate: [AuthGuard, preventBackGuard],
    loadComponent: () =>
      import(
        './pages/marketplace/order-flow/order-confirmation/order-confirmation.component'
      ).then(m => m.OrderConfirmationComponent),
  },
  {
    path: 'order-steps/:step',
    canActivate: [AuthGuard, preventBackGuard],
    loadComponent: () =>
      import(
        './pages/marketplace/order-flow/order-steps/order-steps.component'
      ).then(m => m.OrderStepsComponent),
  },
  {
    path: 'order-details/:reference',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        './pages/marketplace/order-buyer-management/order-buyer-details/order-buyer-details.component'
      ).then(m => m.OrderBuyerDetailsComponent),
  },
  {
    path: 'orders-list',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        './pages/marketplace/order-buyer-management/order-buyer-list/order-buyer-list.component'
      ).then(m => m.OrderBuyerListComponent),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/auth/settings/settings.component').then(
        m => m.SettingsComponent
      ),
  },
  {
    path: 'settings/change-password',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/auth/settings/settings.component').then(
        m => m.SettingsComponent
      ),
  },
  {
    path: 'product-store',
    loadComponent: () =>
      import('./pages/marketplace/product-store/product-store.component').then(
        m => m.ProductStoreComponent
      ),
  },
  {
    path: '',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import('./pages/marketplace/landing/landing.component').then(
        m => m.LandingComponent
      ),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
