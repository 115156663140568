import { Component, effect, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from './toast.service';
import { Toast } from './toast.interface';

@Component({
  selector: 'ecommerce-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit {
  showToast!: boolean;

  toastState: 'success' | 'warning' | 'error' | 'info' = 'info';
  message!: string;

  constructor(private toastService: ToastService) {
    effect(() => {
      const errorMessage = this.toastService.error();
      const successMessage = this.toastService.success();
      if (errorMessage) {
        this.show('error', errorMessage);
      } else if (successMessage) {
        this.show('success', successMessage);
      }
    });
  }

  ngOnInit(): void {
    this.viewToast();
  }

  viewToast() {
    this.toastService.showToast.subscribe((toast: Toast) => {
      this.show(toast.toastState, toast.message);
    });
  }

  show(toastState: 'success' | 'warning' | 'error' | 'info', message: string) {
    if (message) {
      this.toastState = toastState;
      this.message = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    }
  }
}
