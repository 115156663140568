import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class preventBackGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> {
    const itemsCount = JSON.parse(localStorage.getItem('itemsCount') as string);
    if (itemsCount) {
      return of(true);
    } else {
      this.router.navigate(['/']);
      return of(false);
    }
  }
}
