import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';

export const tradeAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  if (localStorage.getItem('user') && user.is_supplier) {
    return true;
  } else if (localStorage.getItem('user') && !user.is_supplier) {
    window.location.href = window.location.origin + '/product-store';
    return false;
  } else {
    window.location.href =
      window.location.origin + '/login?target=' + state.url;
    return false;
  }
};
