import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastService } from '../components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private toast: ToastService
  ) {}

  canActivate(): boolean {
    if (!localStorage.getItem('token')) {
      return true;
    } else {
      this.router.navigate(['/product-store']);
      return false;
    }
  }
}
